import SlimSelect from "slim-select";

const getElementOptions = (element, settings = {}, minimumInputLength = 2) => {
  const options = {
    select: element,
    events: {
      // beforeOpen: () => {
      //   if(!dataLoaded) {
      //     initSelection(select)
      //     dataLoaded = true;
      //   }
      // },  

      beforeChange: (newVal, oldVal) => {
        const event = new CustomEvent("beforeChange", {
          detail: {newVal, oldVal},
          cancelable: true
        })
        return element.dispatchEvent(event)
      }
    },
    settings: settings || {}
  }
  const modal = element.closest('.modal')
  if(modal) {
    options.settings.contentLocation = element.parentNode
    options.settings.contentPosition = 'relative'
  }
  if(element.getAttribute('placeholder')) {
    options.settings.placeholderText = element.getAttribute('placeholder')
  }
  if(element.multiple) {
    options.settings.closeOnSelect = false
    options.settings.allowDeselect = true
    options.settings.hideSelected = true
  }
  if(element.classList.contains('slim-addable')) {
    options.events.addable = function (value) { 
      if (value.length < minimumInputLength) {
        return false
      }
      return value 
    }
  }
  return options;
}

export const applySlimSelectWithData = (element, data) => {
  const slimSelect = new SlimSelect(getElementOptions(element))
  slimSelect.setData(data)
}

export const applySlimSelect = (element, settings = {}) => {
  return new SlimSelect(getElementOptions(element, settings))
}


export const loadSlimSelect = (selector, dom) => {
  dom ||= document
  dom.querySelectorAll(selector).forEach(element => {
    new SlimSelect(getElementOptions(element))
  })
}

export const loadSlimSelectAjax = (selector, dom) => {
  dom ||= document
  dom.querySelectorAll(selector).forEach(element => {
    const minimumInputLength = 2;  
    let dataLoaded = false;
    let initialData = [];
    let controller;
    let signal;
    const urlPath = element.dataset.url;
    const responseDataKey = element.dataset.collection
    const textField = element.dataset.textField || 'name'
    const queryField = element.dataset.queryField || 'q[name_cont]'

    const formatRecord = (record) => ({ value: record.id.toString() || record.value, text: record[textField] || record.text || record.label })

    const loadInitialDataConditionally = () => {
      if(!dataLoaded) {
        if(controller) controller.abort("loading...");
        controller = new AbortController();
        const signal = controller.signal;
        initSlimSelection({
          select, 
          element, 
          signal, 
          urlPath,
          responseDataKey,
          formatRecord
        }).then((data) => {
          controller = null;
          initialData = data;
        })
        dataLoaded = true;
      }
    }
  
    const options = getElementOptions(element, {}, minimumInputLength)
    const select = new SlimSelect({
      ...options,
      select: element,
      events: {
        beforeOpen: loadInitialDataConditionally,
        search: (search, selectedOptions) => {
          const currentData = select.getData();
          if (search.length < minimumInputLength) {
            return handleSearchMinimumLength(minimumInputLength, currentData, initialData);
          }
          const searchParams = {}
          searchParams[queryField] = search
      
          if(controller) controller.abort();
          controller = new AbortController();
          signal = controller.signal;
          return performSlimSearch({
            element, 
            signal, 
            urlPath,
            searchParams,
            formatRecord,
            responseDataKey,
            currentData,
            selectedOptions
          }).finally(() => {
            controller = null;
          })  
        },
        ...options.events,
      }
    })
    // if(!dataLoaded) {
      // initSelection(select)
      // dataLoaded = true;
    // }

  })
}

const initSlimSelection = ({
  select, element, signal, urlPath, additionalParams = {},
  formatRecord, responseDataKey
}) => {
  let selectedValues = element.value ? [element.value] : []
  if(element.multiple) {
    // return Promise.resolve(); //values are already initialized
    selectedValues = Array.from(element.querySelectorAll('option:checked')).map(option => option.value)
    if(selectedValues.length < 1) return Promise.resolve()
    if(element.querySelector('option:checked').text) {
      return Promise.resolve() //has the required options text
    }
  }
  const fullUrl = new URL(location.origin + urlPath);
  const urlSearchParams = fullUrl.searchParams
  Object.keys(additionalParams).forEach(key => {
    urlSearchParams.append(key, additionalParams[key])
  })
  // if(element.value) searchParams.append("q[id_eq]", element.value);
  // if(selectedValues.length > 0) searchParams.append("q[id_in]", selectedValues);
  if(element.dataset.storeId) urlSearchParams.append("store_id", element.dataset.storeId);

  return fetch(`${fullUrl.pathname}?${urlSearchParams.toString()}`, {
    headers: apiHeaders(),
    signal
  }).then(response => response.json().then(data => {
    // if (element.multiple) {
    //   select.setData(data[responseDataKey].map(formatRecord).map((o) => ({...o, selected: true})));
    // } else {
      const records = (responseDataKey ? data[responseDataKey] : data) || []
      formatRecord ||= (record) => ({ value: record.id.toString() || record.value, text: record.text || record.name || record.label })
      const selectData = records.map(formatRecord)
      let initialData = [{text: element.getAttribute('placeholder') || '', value: ''}].concat(selectData)
      
      const currentData = select.getData();
      
      const additionalRecords = currentData.filter(option => {
        return !initialData.some((optionData) => optionData.value === option.value)
      });
      additionalRecords.forEach(record => {
        initialData.push({text: record.text, value: record.value})
      })
      initialData = initialData.sort((a,b) => a.value === "" ? -1 : (b.value === "" ? 1 : a.text.localeCompare(b.text)));
      select.setData(initialData);
      select.setSelected(selectedValues);
      return initialData;
      // if(records.length > 0) {
      //   const option = formatRecord(records[0])
      //   option.selected = true
      //   select.setData([option]);  
      // }
    // }
  }));
}

export const performSlimSearch = ({
  element, 
  signal, 
  urlPath,
  searchParams,
  formatRecord, 
  responseDataKey,
  currentData,
  selectedOptions
}) => {
  const fullUrl = new URL(location.origin + urlPath);
  const urlSearchParams = fullUrl.searchParams
  Object.keys(searchParams).forEach(key => {
    urlSearchParams.append(key, searchParams[key])
  })

  if(element.dataset.storeId) urlSearchParams.append("store_id", element.dataset.storeId);

  return fetch(`${fullUrl.pathname}?${urlSearchParams.toString()}`, {
    headers: apiHeaders(),
    signal
  }).then(response => response.json()).then(data => {
    let options = (responseDataKey ? data[responseDataKey] : data).map(formatRecord);
    options = [{text: element.getAttribute('placeholder'), value: ''}].concat(options);
    const newOptions = options.filter(option => {
      return !selectedOptions.some((optionData) => optionData.value === option.value)
    });
    const combinedOptions = []
    newOptions.forEach(option => {
      const currentOption = currentData.find(optionData => optionData.value === option.value)
      if(currentOption) {
        currentOption.data = option.data;
        combinedOptions.push(currentOption)
      } else {
        if(option.value) {
          combinedOptions.push(option)
        }  
      }
    })
    return combinedOptions;
  })
}

const apiHeaders = () => (
  Rails ? {
    "Authorization": `Bearer ${ Rails.access_token }`,
    "Content-Type": "application/json",
    "Accept": "application/json"
  } : {
    // "Authorization": `Bearer ${ Rails.access_token }`,
    "Content-Type": "application/json",
    "Accept": "application/json"
  }
)

export const performSlimSearchWithCreateNew = ({
  element, 
  signal, 
  urlPath,
  searchParams,
  formatRecord, 
  responseDataKey,
  currentData,
  selectedOptions,
  createNew = false
}) => {
  const fullUrl = new URL(location.origin + urlPath);
  const urlSearchParams = fullUrl.searchParams
  Object.keys(searchParams).forEach(key => {
    urlSearchParams.append(key, searchParams[key])
  })

  if(element.dataset.storeId) urlSearchParams.append("store_id", element.dataset.storeId);

  return fetch(`${fullUrl.pathname}?${urlSearchParams.toString()}`, {
    headers: apiHeaders(),
    signal
  }).then(response => response.json()).then(data => {
    const options = data[responseDataKey].map(formatRecord);
    if(options.length < 1 && createNew) {
      options.push({
        text: 'Create New',
        value: urlSearchParams.values[0] || 0
      })
    }
    const newOptions = options.filter(option => {
      return !selectedOptions.some((optionData) => optionData.value === option.value)
    });
    const combinedOptions = []
    newOptions.forEach(option => {
      const currentOption = currentData.find(optionData => optionData.value === option.value)
      if(currentOption) {
        currentOption.data = option.data;
        combinedOptions.push(currentOption)
      } else {
        if(option.value) {
          combinedOptions.push(option)
        }  
      }
    })
    return combinedOptions;
  })
}

const handleSearchMinimumLength = (minimumInputLength, currentData, initialData) => {
  if(initialData.length) {
    return initialData;
  } else {
    return Promise.reject(`Search must be at least ${minimumInputLength} characters`)
  }
}
export const applySlimSelectAutocompleteWithCreateNew = ({
  element, 
  urlPath,
  formatRecord,
  getInitialParams,
  getSearchParams,
  responseDataKey,
  minimumInputLength = 1,
  createNew = false,
  events = {}
}) => {
  let controller;
  let dataLoaded = false;
  let previousSearch;
  let initialData = [];
  const loadInitialDataConditionally = () => {
    if(!dataLoaded) {
      if(controller) controller.abort("loading...");
      controller = new AbortController();
      const signal = controller.signal;
      const additionalParams = getInitialParams ? getInitialParams() : {}
      initSlimSelection({
        select, 
        element, 
        signal, 
        urlPath,
        formatRecord,
        additionalParams, 
        responseDataKey 
      }).then((data) => {
        initialData = data;
        controller = null;
      })
      dataLoaded = true;
    }
  }
  const select = new SlimSelect({
    select: element,
    events: {
      beforeOpen: loadInitialDataConditionally,
      beforeChange: (newVal, oldVal) => {
        if(newVal.find(option => option.text === 'Create New')) {
          select.close();
          
          // const modal = document.querySelector(element.dataset.modalSelector);
          // modal.style.display = 'block';
          // modal.classList.add('in');
          $('#add_option_value').modal('show');//TODO should be pure javascript, but then close button doesn't work          
          return false;
        }
        return true;
      },
      search: (search, selectedOptions) => {
        const currentData = select.getData();
        if (search.length < minimumInputLength) {
          return handleSearchMinimumLength(minimumInputLength, currentData, initialData);
        }
        if(previousSearch && previousSearch == search) { //avoid unnecessary api call - bug in slim select
          previousSearch = null;
          return Promise.resolve(currentData); 
        }
        previousSearch = search;
        if(controller) controller.abort("loading...");
        controller = new AbortController();
        const signal = controller.signal;

        return performSlimSearchWithCreateNew({
          element, 
          signal, 
          urlPath,
          searchParams: getSearchParams(search),
          formatRecord,
          responseDataKey,
          currentData,
          selectedOptions,
          createNew
        }).finally(() => {
          controller = null;
        })
      },
      ...events
    }
  });  
  // loadInitialDataConditionally();
  return select;
}

export const applySlimSelectAutocomplete = ({
  element, 
  urlPath,
  formatRecord,
  getInitialParams,
  getSearchParams,
  responseDataKey,
  minimumInputLength = 1,
  settings = {},
  events = {}
}) => {
  let controller;
  let dataLoaded = false;
  let initialData = [];
  let previousSearch;

  const loadInitialDataConditionally = () => {
    if(!dataLoaded) {
      if(controller) controller.abort("loading...");
      controller = new AbortController();
      const signal = controller.signal;
      const additionalParams = getInitialParams ? getInitialParams() : {}
      dataLoaded = true;
      return initSlimSelection({
        select, 
        element, 
        signal, 
        urlPath,
        formatRecord,
        additionalParams, 
        responseDataKey 
      }).then((data) => {
        initialData = data;
        controller = null;
      })
    } else {
      return Promise.resolve()
    }
  }
  const options = getElementOptions(element, settings, minimumInputLength);
  const select = new SlimSelect({
    ...options,
    events: {
      ...options.events,
      beforeOpen: loadInitialDataConditionally,
      search: (search, selectedOptions) => {
        const currentData = select.getData();
        if (search.length < minimumInputLength) {
          return handleSearchMinimumLength(minimumInputLength, currentData, initialData);
        }
        if(previousSearch && previousSearch == search) { //avoid unnecessary api call - bug in slim select
          previousSearch = null;
          return Promise.resolve(currentData); 
        }
        previousSearch = search;
        if(controller) controller.abort("loading...");
        controller = new AbortController();
        const signal = controller.signal;

        return performSlimSearch({
          element, 
          signal, 
          urlPath,
          searchParams: getSearchParams ? getSearchParams(search) : {term: search},
          formatRecord,
          responseDataKey,
          currentData,
          selectedOptions
        }).finally(() => {
          controller = null;
        })
      },
      ...events
    }
  });  
  // loadInitialDataConditionally();
  return select;
}

export const applySlimSelectAutocompleteAndCreate = ({
  element, 
  urlPath,
  createUrlPath,
  createUrlLabel,
  formatRecord,
  getInitialParams,
  getSearchParams,
  responseDataKey,
  minimumInputLength = 1,
  events = {}
}) => {
  let controller;
  let dataLoaded = false;
  let createNewAdded = false;
  let initialData = []
  let previousSearch;

  const loadInitialDataConditionally = () => {
    if(!dataLoaded) {
      if(controller) controller.abort("loading...");
      controller = new AbortController();
      const signal = controller.signal;
      const additionalParams = getInitialParams ? getInitialParams() : {}
      dataLoaded = true;
      return initSlimSelection({
        select, 
        element, 
        signal, 
        urlPath,
        formatRecord,
        additionalParams, 
        responseDataKey 
      }).then((data) => {
        controller = null;
        initialData = data;
      })
    } else {
      return Promise.resolve()
    }
  }
  const addCreateOption = (data) => {
    if(data.length < 1) {
      data.push({
        text: '',
        value: ''
      })
    }
    data.push({
      text: createUrlLabel,
      html: `<u><i>${createUrlLabel}</i></u>`,
      value: "__create__",
      selected: false,
    })
    return data;
  }

  const options = getElementOptions(element, {}, minimumInputLength);
  const select = new SlimSelect({
    ...options,
    events: {
      ...options.events,
      beforeOpen: () => {
        loadInitialDataConditionally().then(() => {    
          if(!createNewAdded) {
            select.setData(addCreateOption(select.getData()));  
            createNewAdded = true;
          }
        })              
      },
      error: err => {
        console.error(err)
      },
      beforeChange: (newVal, oldVal) => {
        if(newVal == "__create__" || newVal?.[0]?.value == "__create__") {
          fetch(createUrlPath, {
            headers: {Accept: "text/javascript"}
          })
          .then(response => response.ok ? response.text() : Promise.reject("Error from server"))
          .then(data => [eval][0](data))
          return false;
        }
        return true;
      },
      search: (search, selectedOptions) => {
        const currentData = select.getData();
        if (search.length < minimumInputLength) {
          return handleSearchMinimumLength(minimumInputLength, currentData, initialData);
        }
        if(previousSearch && previousSearch == search) { //avoid unnecessary api call - bug in slim select
          previousSearch = null;
          return Promise.resolve(currentData); 
        }
        previousSearch = search;
        if(controller) controller.abort("loading...");
        controller = new AbortController();
        const signal = controller.signal;

        return performSlimSearch({
          element, 
          signal, 
          urlPath,
          searchParams: getSearchParams ? getSearchParams(search) : {term: search},
          formatRecord,
          responseDataKey,
          currentData,
          selectedOptions
        })
        .then(data => {
          return addCreateOption(data)
        })
        .finally(() => {
          controller = null;
        })
      },
      ...events
    }
  }); 

  // loadInitialDataConditionally().then(() => {    
  //   select.setData(addCreateOption(select.getData()));  
  // })


  return select;
}

export const applySlimSelectWithAutocompleteSource = (element, availableTags) => {
  const minimumInputLength = 2;
  let controller;
  let signal;

  const select = new SlimSelect({
    select: element,
    settings: {
      keepOrder: true
    },
    events: {
      search: (search, selectedOptions) => {
        const currentData = select.getData();
        if (search.length < minimumInputLength) {
          return handleSearchMinimumLength(minimumInputLength, currentData, []);
        }

        if(controller) controller.abort();
        controller = new AbortController();
        signal = controller.signal;
      
        return fetch(element.dataset.autocompleteSource, {
          body: JSON.stringify({
            term: search
          }),
          signal
        }).then(response => response.json()).then(data => {
          controller = null;
          return data.users.filter(user => {
            return !currentData.some((optionData) => optionData.value === formatUser(user).value)
          }).map(user => formatUser(user));
        })
      }
    }
  });
  // const availableTags = element.dataset.autocompleteSource;
  if(availableTags) {
    select.setData(availableTags.map(tag => {
      return {
        text: tag,
        value: tag
      }
    }));  
  }
}

export const applySlimSelectWithSource = (element, availableTags) => {
  const minimumInputLength = 2;
  let controller;
  let signal;

  const select = new SlimSelect({
    select: element,
    settings: {
      keepOrder: true
    },
    events: {
      search: (search, selectedOptions) => {
        if (search.length < minimumInputLength) {
          return Promise.reject(`Search must be at least ${minimumInputLength} characters`)
        }

        if(controller) controller.abort();
        controller = new AbortController();
        signal = controller.signal;
      
        return fetch(element.dataset.source, {
          body: JSON.stringify({
            term: search
          }),
          signal
        }).then(response => response.json()).then(data => {
          controller = null;
          return data.users.filter(user => {
            return !currentData.some((optionData) => optionData.value === formatUser(user).value)
          }).map(user => formatUser(user));
        })
      }
    }

  });
  if(availableTags) {
    select.setData(availableTags.map(tag => {
      return {
        text: tag,
        value: tag
      }
    }));  
  }
}