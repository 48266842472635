import Uri from 'jsuri/Uri.js';

const Spree = window.Spree || {};
Spree.ready = function(callback) {
  return document.addEventListener("spree:load", function() {
    return callback(jQuery);
  });
};

Spree.mountedAt = function() {
  return '/';
  //return window.SpreePaths.mounted_at;
};

Spree.adminPath = function() {
  return 'admin/';
  // return window.SpreePaths.admin
};

Spree.pathFor = function(path) {
  var locationOrigin = (window.location.protocol + '//' + window.location.hostname) + (window.location.port ? ':' + window.location.port : '');

  return this.url('' + locationOrigin + (this.mountedAt()) + path, this.url_params).toString();
};

Spree.localizedPathFor = function(path) {
  if (typeof (SPREE_LOCALE) !== 'undefined' && typeof (SPREE_CURRENCY) !== 'undefined') {
    var fullUrl = new URL(Spree.pathFor(path));
    var params = fullUrl.searchParams;
    var pathName = fullUrl.pathname;

    params.set('currency', SPREE_CURRENCY);

    if (pathName.match(/api\/v/)) {
      params.set('locale', SPREE_LOCALE);
    } else {
      pathName = (this.mountedAt()) + SPREE_LOCALE + '/' + path;
    }
    return fullUrl.origin + pathName + '?' + params.toString();
  }
  return Spree.pathFor(path);
};

Spree.adminPathFor = function(path) {
  return this.pathFor('' + (this.adminPath()) + path);
};

Spree.url = function(uri, query) {
  if (uri.path === void 0) {
    // eslint-disable-next-line no-undef
    uri = new Uri(uri);
  }
  if (query) {
    Object.keys(query).forEach(key => {
      return uri.addQueryParam(key, query[value])
    })
  }
  return uri;
};

Spree.ajax = function(urlOrSettings, settings) {
  var url;
  if (typeof urlOrSettings === 'string') {
    return $.ajax(Spree.url(urlOrSettings).toString(), settings);
  } else {
    url = urlOrSettings['url'];
    delete urlOrSettings['url'];
    return $.ajax(Spree.url(url).toString(), urlOrSettings);
  }
};

Spree.routes = {};
Spree.url_params = {};

window.Spree = Spree;
export { Spree };